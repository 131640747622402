import React from 'react'
import { Box } from "@chakra-ui/react"
import { Responsive } from "../../components/atoms/Responsive"
import { FullBleed } from "../../components/wrappers/FullBleed"

function StateMachinesDemo() {
    return (
        <Responsive hideIn={["base", "sm"]}>
            <FullBleed>
                <Box
                    as="iframe"
                    display={["none", null, "block"]}
                    h="lg"
                    w="100vw"
                    src="https://stately.ai/viz/3b7f90b3-1298-40ac-a7c8-866b440a5f57"
                    loading="lazy"
                ></Box>
            </FullBleed>
        </Responsive>
    )
}

export { StateMachinesDemo }
